import { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import { Box } from '@mui/material';

function SuspenseLoader() {
  const [dots, setDots] = useState('');

  useEffect(() => {
    // Start NProgress
    NProgress.start();

    // Cleanup NProgress when component unmounts
    return () => {
      NProgress.done();
    };
  }, []);

  useEffect(() => {
    // Update dots for the loading text
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + '.' : '')); // Loop through 1 to 3 dots
    }, 500);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        background: 'black',
        zIndex: '1000',
        color: 'white', // Text color
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src="/static/images/logo/foza.svg" alt="Foza Logo" />
      <Box
        sx={{
          marginTop: 2, // Space between logo and text
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        Loading{dots}
      </Box>
    </Box>
  );
}

export default SuspenseLoader;
